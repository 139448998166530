import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const LiveEventBanner = () => {
   const today = moment().format('YYYY-MM-DD')
   const showBanner = moment(today).isBetween('2025-04-06', '2025-04-11')
   const scheduleUrl = `/events/bpw-2025/-/schedule/${today}`

   if (!showBanner) return null

   return (
      <Link to={scheduleUrl} className="flex justify-between items-center w-full bg-emerald-50 hover:bg-emerald-100 py-4 lg:py-6 px-6">
         <span className="flex items-center text-emerald-600 font-bold">
            <span className="w-2 h-2 bg-emerald-500 rounded-full mr-2 inline-block animate-pulse" />
            <span className="block md:hidden">LIVE: BPW 2025</span>
            <span className="hidden md:block">LIVE: Banff Pipeline Workshop 2025</span>
         </span>
         <span className="text-emerald-700 underline">
            View Today&apos;s Schedule
         </span>
      </Link>
   )
}

export default LiveEventBanner
